<template>
    <div>

    <nav id="nav" class="navbar navbar-expand-sm py-1 border-0" >
      <div class="mr-auto">
        <div class="hexagon"><img src="/img/BEE1_bee4.svg" class="logo" @click="nekamJit"></div>
      </div>
    </nav>



        <div v-if="$route.params.key &&  !ok"><br>
            <div class="alert alert-danger text-center" v-if="errorMessage">{{ errorMessage }}</div>
        </div>

        <div class="text-center" v-if="$route.params.key==null"> {{ $t('reg.potvrdte-email')}}</div>

        <div v-if="ok " class="text-center">

            <div class="d-flex blokuprostred" >
                <div><img src="/img/ico-ok.png"></div>
                <p>{{ $t('reg.potvrzeno-uvitaci-text')}}</p>
            </div>


        </div>

    </div>
</template>

<script>
  const axios = require("axios");
  export default {
    name: "PotvrzeniRegistrace",
    data(){
      return {
        errorMessage:'',
        ok:false
      }
    },methods:{
      nekamJit() {
        this.$router.push({path: '/'})
      }
    },
    mounted() {

      if (this.$route.params.key && this.$route.params.key.length>10){

        axios.get(process.env.VUE_APP_API_ROOT_URL + "/user/confirm-reg", {params:{token: this.$route.params.key}}).then(result => {
          if (result.status == 200) {
            this.$store.commit('setuser', result.data )

            localStorage.setItem('user-token', result.data.user_token)
            axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.user_token}`

            this.$i18n.locale = result.data.locale
            this.ok=true
          }
        })
          .catch(err => {
            if (err.response.data.errors){
              this.errorMessage = ''

              for (var k in err.response.data.errors){
                console.log ('chyy', k, err.response.data.errors[k][0])
                this.errorMessage += k + " "+ err.response.data.errors[k][0]
              }
            }else{
              this.errorMessage = err.response.data.message;
            }

                //this.ok=true
                //this.$i18n.locale='cs'
          });




      }

    }


  }
</script>

<style scoped lang="scss">

    .blokuprostred{
        max-width: 470px;
        margin: 120px auto;
        font-size: 20px;
        img{ margin-top:10px;}
        p{
            text-align: left;
            margin-left:20px;
        }

    }
</style>
